
export const transactionsInitialState = {
	allTransactions:[], // switch to empty array
	lastTransactions: [],
	rejectedTransactions: [],
	activeTransactions: [],
	clearedTransactions: [],
	filteredTransactions: [],
	error: null,
};
